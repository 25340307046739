import React, { useMemo } from 'react'
import { useQueryParam, StringParam } from "use-query-params";
import {graphql} from 'gatsby'
import SinglePageTemplate from '../../components/singlePageTemplate';
import usePrismicNews from "../../hooks/usePrismicNews";

const RecentPublications = () => {
  const [id] = useQueryParam('id', StringParam)
  const news = usePrismicNews();
  const post = useMemo(() => news.news.find(item => item.id === id), [id]);

  return(
    <SinglePageTemplate
      title={post?.title}
      route="recent-publications"
      image={post?.imageBig}
      description={post?.content}
      usefulLinks={post?.usefulLinks}
    />
  )
}

export default RecentPublications

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`